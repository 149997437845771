@import url(~antd/dist/antd.css);
/*!
 * Bootstrap Grid v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"), local("Montserrat-Light"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gTD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3g3D_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gbD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gfD_vx3rCubqg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Futura Std';
  src: url("./fonts/FuturaStd-Medium.woff2") format("woff2"), url("./fonts/FuturaStd-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Futura Std';
  src: url("./fonts/FuturaStd-Book.woff2") format("woff2"), url("./fonts/FuturaStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura Std';
  src: url("./fonts/FuturaStd-Heavy.woff2") format("woff2"), url("./fonts/FuturaStd-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Futura Std';
  src: url("./fonts/FuturaStd-Bold.woff2") format("woff2"), url("./fonts/FuturaStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.light-grey {
  color: #d4d4d4; }

.near-black {
  color: #191919; }

.pure-white {
  color: #FFFFFF; }

.almost-white {
  color: #F9F9F9; }

.primary-blue {
  color: #153b62 !important; }

.primary-orange {
  color: #f06024 !important; }

.bg-primary-blue {
  background-color: #153b62 !important; }

.bg-primary-orange {
  background-color: #f06024 !important; }

.bg-near-black {
  background-color: #191919 !important; }

.bg-alert-red {
  background-color: #e45327 !important; }

.bg-light-grey {
  background-color: #f7f7f7; }

.bg-pure-white {
  background-color: #FFFFFF; }

.bg-dark-grey {
  background-color: #363636; }

.bg-secondary-black {
  background-color: #201a16; }

.bg-deeper-orange {
  background-color: #fd5c18; }

.shadow {
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.16); }

.no-margin {
  margin: 0px !important; }
  .no-margin__left {
    margin-left: 0px !important; }
  .no-margin__right {
    margin-right: 0px !important; }

.no-padding {
  padding: 0px !important; }
  .no-padding__left {
    padding-left: 0px !important; }
  .no-padding__right {
    padding-right: 0px !important; }
  .no-padding__top {
    padding-right: 0px !important; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

img {
  width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.full-height {
  height: 100%; }

.inline {
  display: inline-block !important; }

.center-block {
  margin: 0px auto !important; }

.hidden {
  display: none !important; }

.uppercase {
  text-transform: uppercase; }

.capitalise {
  text-transform: capitalize; }

.flex {
  display: flex; }

.justify-content-right {
  justify-content: flex-end !important; }

.justify-content-left {
  justify-content: flex-start !important; }

.full-width {
  width: 100%; }

.clickable:hover {
  cursor: pointer; }

.no-wrap {
  word-wrap: inherit !important; }

.no-border {
  border: unset !important; }

.tiny {
  font-size: 0.75rem;
  display: block;
  word-wrap: break-word; }

.small {
  font-size: 0.875rem;
  display: block;
  word-wrap: break-word; }
  .small__smaller {
    font-size: 0.8rem; }

.normal {
  font-size: 1.1rem;
  display: block;
  word-wrap: break-word; }
  .normal__smaller {
    font-size: 0.9rem; }
  .normal__larger {
    font-size: 1.125rem; }

.sub-head {
  font-size: 1.5rem;
  display: block;
  word-wrap: break-word; }
  .sub-head__larger {
    font-size: 1.75rem; }

.heading {
  display: block;
  font-size: 2.125rem;
  word-wrap: break-word; }

.light {
  font-weight: 300; }

.medium {
  font-weight: 500; }

.semi-bold {
  font-weight: 600; }

.bold {
  font-weight: 700; }

.heavy {
  font-weight: 900; }

.spaced {
  letter-spacing: 0.043rem; }

.explanation {
  line-height: 1.5rem;
  margin-bottom: 0;
  margin-top: 0; }

.input-field label {
  display: block; }

.emphasis {
  font-size: 0.975rem; }

.light-grey {
  color: #d4d4d4; }

.near-black {
  color: #191919; }

.pure-white {
  color: #FFFFFF; }

.almost-white {
  color: #F9F9F9; }

.primary-blue {
  color: #153b62 !important; }

.primary-orange {
  color: #f06024 !important; }

.bg-primary-blue {
  background-color: #153b62 !important; }

.bg-primary-orange {
  background-color: #f06024 !important; }

.bg-near-black {
  background-color: #191919 !important; }

.bg-alert-red {
  background-color: #e45327 !important; }

.bg-light-grey {
  background-color: #f7f7f7; }

.bg-pure-white {
  background-color: #FFFFFF; }

.bg-dark-grey {
  background-color: #363636; }

.bg-secondary-black {
  background-color: #201a16; }

.bg-deeper-orange {
  background-color: #fd5c18; }

.btn {
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  padding: 0.565rem 2rem 0.456rem;
  letter-spacing: 0.265rem;
  background-color: #f06024;
  border: 1px solid #f06024;
  color: #FFFFFF;
  font-size: 1.2rem;
  border-radius: 3.8rem; }
  .btn:hover {
    opacity: 0.85;
    cursor: pointer; }
  .btn-default {
    background-color: transparent;
    border: 1px solid #f06024;
    color: #f06024; }
  .btn-primary {
    background-color: #f06024;
    border: 1px solid #f06024;
    padding: 0.765rem 2rem 0.756rem; }
  .btn-secondary {
    background-color: transparent;
    font-size: 1rem;
    padding: 0.765rem 2rem 0.756rem;
    border: 1px solid #FFFFFF;
    color: #FFFFFF; }
  .btn-default {
    background-color: #153b62;
    border: 1px solid #153b62;
    color: #FFFFFF;
    padding: 0.765rem 2rem 0.756rem; }
  .btn:focus {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    outline: none;
    transition: box-shadow 0.25s ease; }
  .btn[disabled] {
    opacity: 0.5;
    transition: opacity 0.25s ease;
    cursor: not-allowed; }

.question-list__item {
  padding-bottom: 1rem; }
  .question-list__item__question {
    padding-bottom: 0.4rem; }
    .question-list__item__question span {
      letter-spacing: 0.015rem;
      font-size: 1rem;
      line-height: 1.5rem; }
  .question-list__item__question-inputs {
    /* The container */
    /* The container */ }
    .question-list__item__question-inputs label {
      margin-bottom: 0.2rem;
      margin-top: 0.3rem;
      font-size: 0.95rem; }
    .question-list__item__question-inputs input, .question-list__item__question-inputs textarea {
      border: 1px solid #d4d4d4;
      padding: 0.275rem;
      font-size: 0.875rem;
      letter-spacing: 0.045rem; }
      .question-list__item__question-inputs input[type="text"], .question-list__item__question-inputs input[type="email"], .question-list__item__question-inputs input[type="number"], .question-list__item__question-inputs textarea[type="text"], .question-list__item__question-inputs textarea[type="email"], .question-list__item__question-inputs textarea[type="number"] {
        min-height: 32.775px;
        width: 100%; }
      .question-list__item__question-inputs input:focus, .question-list__item__question-inputs textarea:focus {
        outline: 2px solid rgba(240, 96, 36, 0.57);
        box-shadow: 0 1px 6px 0 rgba(240, 96, 36, 0.46);
        padding-left: 0.345rem; }
    .question-list__item__question-inputs textarea {
      width: 100%;
      height: 115px; }
    .question-list__item__question-inputs .error {
      color: #ff4205;
      padding-top: 0.25rem;
      line-height: 1.375rem;
      letter-spacing: 0.055rem; }
    .question-list__item__question-inputs .input-checkbox {
      display: block;
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      font-size: 1.3rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      /* Hide the browser's default checkbox */
      /* Create a custom checkbox */
      /* On mouse-over, add a grey background color */
      /* When the checkbox is checked, add a blue background */
      /* Create the checkmark/indicator (hidden when not checked) */
      /* Show the checkmark when checked */
      /* Style the checkmark/indicator */ }
      .question-list__item__question-inputs .input-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 1px;
        left: 0px; }
      .question-list__item__question-inputs .input-checkbox .checkmark {
        position: absolute;
        top: 4.5px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 1px solid #153b62; }
      .question-list__item__question-inputs .input-checkbox:hover input ~ .checkmark {
        background-color: #d4d4d4; }
      .question-list__item__question-inputs .input-checkbox input:checked ~ .checkmark {
        background-color: transparent; }
      .question-list__item__question-inputs .input-checkbox .checkmark:after {
        content: "";
        position: absolute;
        display: none; }
      .question-list__item__question-inputs .input-checkbox input:checked ~ .checkmark:after {
        display: block; }
      .question-list__item__question-inputs .input-checkbox .checkmark:after {
        left: 7px;
        top: 1px;
        width: 5px;
        height: 15px;
        border: solid #153b62;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
    .question-list__item__question-inputs .input-radio {
      display: block;
      position: relative;
      padding-left: 29px;
      cursor: pointer;
      margin-bottom: 0.35rem;
      font-size: 1.3rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      /* Hide the browser's default radio button */
      /* Create a custom radio button */
      /* On mouse-over, add a grey background color */
      /* When the radio button is checked, add a blue background */
      /* Create the indicator (the dot/circle - hidden when not checked) */
      /* Show the indicator (dot/circle) when checked */
      /* Style the indicator (dot/circle) */ }
      .question-list__item__question-inputs .input-radio span {
        padding-top: 2px; }
      .question-list__item__question-inputs .input-radio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 1px; }
      .question-list__item__question-inputs .input-radio span.checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 1px solid #153b62;
        border-radius: 50%; }
      .question-list__item__question-inputs .input-radio:hover input ~ .checkmark {
        background-color: #d4d4d4; }
      .question-list__item__question-inputs .input-radio input:checked ~ .checkmark {
        background-color: transparent; }
      .question-list__item__question-inputs .input-radio span.checkmark:after {
        content: "";
        position: absolute;
        display: none; }
      .question-list__item__question-inputs .input-radio input:checked ~ .checkmark:after {
        display: block; }
      .question-list__item__question-inputs .input-radio span.checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #153b62; }
    .question-list__item__question-inputs .input-file-upload .file-link {
      color: #153b62; }
      .question-list__item__question-inputs .input-file-upload .file-link:hover {
        text-decoration: underline; }
    .question-list__item__question-inputs .input-file-upload .icon {
      height: 20px;
      width: auto;
      vertical-align: bottom;
      margin-right: 0.5rem; }
    .question-list__item__question-inputs .input-file-upload .cancel-btn {
      border: 1px solid #191925;
      padding: 0.25rem 0.85rem;
      font-size: 0.875rem;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-left: 0.85rem; }
      .question-list__item__question-inputs .input-file-upload .cancel-btn:hover {
        cursor: pointer; }

.login-wrapper {
  width: 330px;
  background-color: #fff;
  padding: 1rem 1.25rem; }
  .login-wrapper .form-button {
    width: 100%; }

.logo-container {
  padding: 0.35rem 1rem 0.95rem;
  background-color: white; }

.login-page {
  height: 100vh; }

.dashboard-content {
  min-height: 48rem;
  min-height: 100vh; }

.sidebar {
  padding: 0rem 0 1rem; }
  .sidebar .sidebar-link {
    padding: 0.65rem 1rem 0.5rem;
    display: block;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 1px; }
    .sidebar .sidebar-link.active {
      background-color: #f06024; }
    .sidebar .sidebar-link:hover {
      opacity: 0.9; }

.connectivity-alert {
  text-align: center;
  padding: 0.5rem;
  color: #FFFFFF;
  background-color: #e63600; }

.logo-container {
  background-color: #fff;
  display: block;
  text-align: center;
  padding-top: 0.55rem;
  padding-bottom: 0.75rem; }
  .logo-container .primary-logo {
    height: 39px; }

.toolbar {
  padding-top: 2rem; }

.main-content {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem; }

.user-table .ant-table-thead > tr > th, .user-table .ant-table-tbody > tr > td {
  padding: 9px 16px !important; }
  .user-table .ant-table-thead > tr > th:hover, .user-table .ant-table-tbody > tr > td:hover {
    cursor: pointer; }

.ant-table-pagination.ant-pagination {
  margin: 16px 0 !important; }

.search-bar {
  padding-bottom: 1.4rem; }
  .search-bar .ant-select-selection__rendered {
    line-height: 34px !important; }
  .search-bar .ant-select-selection--single, .search-bar .ant-input {
    height: 36px !important; }

.detail-info {
  padding-bottom: 1rem; }

.cdr-banner .explanation {
  font-size: 0.95rem; }
  .cdr-banner .explanation .emphasis {
    font-size: 0.9rem; }
  .cdr-banner .explanation:last-child {
    margin-bottom: 0px; }

.cdr-banner .sub-title {
  margin-bottom: 1rem; }

.cdr-table {
  border-collapse: collapse; }
  .cdr-table thead tr th {
    padding: 1rem;
    text-align: left;
    border: 2px solid #f9c35e;
    color: #153b62;
    letter-spacing: 1px; }
  .cdr-table tbody tr td {
    padding: 1rem;
    text-align: left;
    border: 2px solid #f9c35e;
    margin: 0px; }

.tab-body .ant-card-body {
  padding: 0px !important; }

.field-answer {
  color: #f06024; }

.responses-container, .cdr-banner, .pre-loader {
  padding: 24px 32px; }

.response-sidebar {
  border-right: 2px solid rgba(242, 95, 3, 0.2); }

.questions-container {
  padding-left: 2rem; }

.navigation-item {
  text-align: right;
  text-transform: uppercase; }
  .navigation-item .navigation-item__link {
    padding: 0.6rem 0rem;
    line-height: 1.25rem;
    letter-spacing: 0.065rem;
    display: block; }
    .navigation-item .navigation-item__link span {
      padding-right: 1rem;
      padding-left: 1rem; }
    .navigation-item .navigation-item__link .fake-border {
      width: 80%; }
    .navigation-item .navigation-item__link.active, .navigation-item .navigation-item__link:hover {
      color: #f06024;
      border-bottom: none;
      cursor: pointer; }
      .navigation-item .navigation-item__link.active span, .navigation-item .navigation-item__link:hover span {
        color: #FFFFFF; }
      .navigation-item .navigation-item__link.active:after, .navigation-item .navigation-item__link:hover:after {
        border-color: #fd5c18; }

.question-title {
  margin-left: -0.9rem;
  border-bottom: 1px solid #f06024;
  padding-bottom: 0.25rem;
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1rem; }

.question-list {
  list-style: decimal; }
  .question-list.multiple_questions {
    margin-left: 1rem; }
  .question-list__item {
    padding-bottom: 1rem; }
    .question-list__item__question {
      padding-bottom: 0.2rem;
      padding-top: 0.4rem !important;
      font-size: 1rem; }

.table-wrapper {
  overflow-y: scroll;
  margin-top: 0.5rem; }

.table {
  width: 100%; }
  .table .input-field label {
    display: none; }

.input-field {
  overflow-wrap: break-word; }

.role-form {
  text-align: center; }
  .role-form .form-button {
    width: 15%; }

.profile-info-form {
  margin: 0px auto !important;
  width: 385px; }

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  position: relative !important; }
  .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    width: auto !important;
    padding: 1.1rem 0.5rem !important;
    border: 1px solid #f06024 !important;
    height: 100px !important;
    margin-bottom: 1rem;
    max-width: 90%; }

.ant-upload-list-picture .ant-upload-list-item {
  height: auto !important; }

.ant-upload-list-picture .ant-upload-list-item-name {
  display: none !important; }

.ant-upload-list-picture .ant-upload-list-item-name {
  height: auto !important;
  padding: 0px !important;
  padding-left: 0.5rem !important; }

.applicant-detail .action-bar .action-item {
  display: inline-block;
  margin-left: 1rem; }
  .applicant-detail .action-bar .action-item:hover {
    cursor: pointer;
    opacity: 0.85; }

.footer-control {
  position: absolute;
  bottom: 15px;
  width: 100%;
  padding: 0.65rem 1rem 0.5rem;
  display: flex;
  align-items: center; }
  .footer-control:hover {
    cursor: pointer; }
  .footer-control .anticon {
    margin-right: 0.5rem;
    font-size: 1.25rem; }

.verification-field {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

.table-header {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.75rem; }
  .table-header .small {
    letter-spacing: 1px; }

.table-action-bar {
  margin-bottom: 1rem; }

.ant-btn-primary[disabled] {
  color: rgba(58, 56, 56, 0.57) !important;
  background-color: #8dbde4 !important;
  border-color: #8dbde4 !important; }

html {
  font-size: 16px;
  height: 100%; }

body {
  font-family: 'Futura Std', 'Montserrat', sans-serif !important;
  font-weight: 400 !important;
  color: #201a16 !important;
  padding: 0px !important;
  margin: 0px !important;
  height: 100% !important;
  background-color: #f7f7f7 !important; }

#root {
  height: 100%; }

a {
  text-decoration: none; }

ol, ul, p {
  margin: 0 !important;
  padding: 0;
  list-style: none; }

.form-field {
  margin-bottom: 1.2rem; }
  .form-field label {
    margin-bottom: 0.3rem;
    letter-spacing: 0.24px;
    display: block; }

.alert {
  padding: 0.35rem 0.8rem;
  border: 1px solid #e04111;
  background-color: #e45327;
  color: white;
  font-weight: 500;
  line-height: 1.2rem;
  text-transform: capitalize;
  margin-bottom: 0.95rem;
  overflow-wrap: break-word; }
  .alert.success {
    border-color: #1ab684;
    background-color: #1ab684; }
  .alert.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba; }
  .alert.loading {
    border-color: transparent;
    background-color: transparent;
    color: #363636;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }

.inline-link span:hover {
  text-decoration: underline; }

.waiting {
  padding: 0.35rem 0.8rem;
  margin-bottom: 1.1rem;
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2rem; }

img {
  width: 100%;
  height: auto; }

.primary-logo {
  height: 50px;
  width: auto; }

.ant-table-pagination.ant-pagination {
  width: 100%;
  text-align: center; }

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem; }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem;
  opacity: 1; }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem;
  opacity: 1; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem; }

::placeholder {
  /* Most modern browsers support this now. */
  color: rgba(255, 255, 255, 0.47);
  text-transform: uppercase;
  font-size: 0.8rem; }
